#work-area{
    position: relative;
}
#display-area{
    text-align: right;
    padding: 20px 15px;
    font-size: 50px;
    position: absolute;
    bottom: 0px;
    right: 10px;
    font-weight: 100;
}
#buttons-div{
    text-align: center;
    position: absolute;
    bottom: 12px;
    width: 100%;
}
.btn-row{
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 15px;
}